import React from 'react';
import {
  useBreakpointValue,
  Flex,
  Link,
  Heading,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  HStack
} from '@chakra-ui/react';

import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useColorModeValue } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import nerdhiveLogo from '../../assets/images/nerdhive-icon.png';

const Header = () => {
  const bgColorNav = useColorModeValue('blackAlpha.100', 'teal.800');

  return (
    <Flex bg={bgColorNav}  direction='row' justifyContent='space-between' width='full'>
      <Flex align='center'>
        <Image
          borderRadius='full'
          boxSize='70px'
          src={nerdhiveLogo}
          alt='nerdhive logo'
        />
        <Heading size='ml' fontFamily='logo' letterSpacing="2px" ml='2'>
          Nerdhive
        </Heading>
      </Flex>
      <HStack>
        {useBreakpointValue({ base: <SmallScreenNav />, md: <LargeScreenNav /> })}
        <ColorModeSwitcher />
      </HStack> 
    </Flex>
  );
};





function LargeScreenNav() {
  return (
    <>
      <Link href='/home' passHref>
            <Button variant='ghost'>Home</Button>
          </Link>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Tools
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Link href='/studywithme' passHref>
                  Study With Me
                </Link>
              </MenuItem>
              <MenuItem>
                <Link href='/askatutor' passHref>
                  Ask a Tutor
                </Link>
              </MenuItem>
              <MenuItem>
                <Link href='/askfiona' passHref>
                  Ask Fiona
                </Link>
              </MenuItem>
              <MenuItem>
                <Link href='/smartrevision' passHref>
                  Smart Revision
                </Link>
              </MenuItem>
              {/* <MenuItem>
                <Link href='/mathassist' passHref>
                  Math Assist
                </Link>
              </MenuItem> */}
            </MenuList>
          </Menu>
          {/* <Link href='/blog' passHref>
            <Button variant='ghost'>Blog</Button>
          </Link> */}
          <Link href='/signup' passHref>
            <Button variant='ghost'>Sign Up</Button>
          </Link>
    </>
  )
}



function SmallScreenNav() {
  return (
    <>
    <Menu >
          <MenuButton as={IconButton} 
           aria-label='Options'
           icon={<HamburgerIcon />}
           variant='outline'
          />
          <MenuList>
            <MenuItem>
              <Link href='/home' passHref>
                Home
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href='/studywithme' passHref>
                Study With Me
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href='/askatutor' passHref>
                Ask a Tutor
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href='/askfiona' passHref>
                Ask Fiona
              </Link>
            </MenuItem>
            <MenuItem>
              <Link href='/smartrevision' passHref>
                Smart Revision
              </Link>
            </MenuItem>
            {/* <MenuItem>
              <Link href='/mathassist' passHref>
                Math Assist
              </Link>
            </MenuItem> */}
            {/* <MenuItem>
              <Link href='/blog' passHref>
                Blog
              </Link>
            </MenuItem> */}
            <MenuItem>
              <Link href='/signup' passHref>
                <Button width='200px' colorScheme='blue'>Sign Up</Button>
            </Link>
            </MenuItem>
          </MenuList>
        </Menu>
    </>
  )
}



export default Header;
