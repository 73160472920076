import React from 'react'
import { Flex, Heading, Text, VStack,Button,Link,Box,useColorModeValue} from '@chakra-ui/react'

function StudyWithMe() {
  return (  
    <VStack m={2} px={2} borderBottomWidth={2}  pb={4}  minH={['auto','100vh']}>
      <Heading  mt={2} size={['xl', '2xl']} fontFamily="logo" textAlign='center'>
        Study Smarter with Fiona
      </Heading>
      <Text as="em" >
        Your study partner and personal tutor
      </Text>
      <Box mt={6}>
        <Heading size="lg">Never Get Stuck Again</Heading>
      </Box>
      <Box>
        <Text fontSize={['md', 'lg', 'xl']} fontWeight="bold" textAlign='center'>
          With our "Study with Me" feature, just <Text as="span" color="green.400">snap and send</Text>,
          and Fiona will help you overcome the hurdles in your way.
        </Text>
      </Box>
     
  
      <Flex direction='column' textAlign='start' p={2} marginTop={4}>
        <Flex direction='column' shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('blackAlpha.100', 'black')} borderRadius='md'>

          <Text fontSize={['md', 'lg', 'xl']} mt={2} ml={3} color='blue.300' fontWeight="bold">
           Here's how it works:
          </Text>
          <VStack align='start' spacing={['2','5']}  p={4}>
            <Text>
              📸 Take a picture of the page you're studying.
            </Text>
            <Text>
              🧠 Fiona will explain concepts in a simple and easy-to-understand way.
            </Text>
            <Text>
              🌟 Highlighting key takeaways for better understanding.
            </Text>
            <Text>
              ❓ Extracting possible questions an examiner might ask you.
            </Text>
            <Text>
              💬 Chat with Fiona about anything on the page.
            </Text>
          </VStack>
        </Flex>
      </Flex>
  
    <Flex p={1}>
        <Link href='/signup' passHref>
            <Button width='300px' colorScheme='blue'>Sign Up</Button>
        </Link>
    </Flex>
  
    <Flex p={2}>
        <Heading as='h1'size='lg' fontFamily='logo' textAlign='center'>
            It's like having a tutor in your pocket.
        </Heading>
    </Flex>
</VStack>
  )
}

export default StudyWithMe