// theme.js

import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools'

// const theme = extendTheme({
//   fonts: {
//     body: 'Poppins, sans-serif',
//     heading: 'Rubik, sans-serif',
//     logo:'Finger Paint'
//     // ... other font definitions
//   },
//   colors: {
//     dark: {
//       100: 'teal.800', // Set the background color for dark mode to black
//     },
   

//   },
//    global: (props) => ({
//     body: {
//       fontFamily: 'body',
//       color: mode('gray.800', 'whiteAlpha.900')(props),
//       bg: mode('white', 'teal.800')(props),
//       lineHeight: 'base',
//     },
//     '*::placeholder': {
//       color: mode('gray.400', 'whiteAlpha.400')(props),
//     },
//     '*, *::before, &::after': {
//       borderColor: mode('gray.200', 'whiteAlpha.300')(props),
//       wordWrap: 'break-word',
//     },
//   }),
//   initialColorMode: 'dark',
//   useSystemColorMode: false,
// });

const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          fontFamily: 'body',
          color: mode('gray.800', 'whiteAlpha.900')(props),
          bg: mode('blackAlpha.100','whiteAlpha.400')(props),
          lineHeight: 'base',
        },
      }),
    },
    fonts: {
        body: 'Poppins, sans-serif',
        heading: 'Rubik, sans-serif',
        logo:'Finger Paint',
        poppins:'Poppins'
        // ... other font definitions
      },
    initialColorMode: 'dark',
    useSystemColorMode: false,
  })

export default theme;
