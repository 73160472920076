import {Flex, useColorModeValue,Box,Heading, Text, VStack,Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom';

function OurTools() {
    return (
      <VStack align="center" spacing={6} p={8}>
        <Heading as="h2" size="xl">
         Our tools address specific challenges faced by learners
        </Heading>
        <VStack>
        <Text fontSize={['md', 'lg', 'xl']} fontWeight="bold" >
        What problems are you currently facing?
        </Text>
        <Text fontSize={['sm', 'md', 'lg']} >
        Choose below can see how we solve it for you
        </Text>
        </VStack>
        <VStack>
            <Box spacing={1} borderColor={useColorModeValue('teal.500', 'teal.300')} borderWidth={2} p={2}
                as='button'
                transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                _active={{
                bg: useColorModeValue('teal.200', 'gray.600'),
                transform: 'scale(0.98)',
                borderColor: useColorModeValue('teal.300', 'gray.500'),
                }}
                _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                <Text fontSize={['sm', 'md', 'lg']} >
                Studying Problems
                </Text>
                <Link to="/studywithme">
                <Box as='button' bg={useColorModeValue('teal.100', 'gray.800')} m={1} p={4} borderRadius="md"  _hover={{ bg: useColorModeValue('teal.100', 'gray.700') }} _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                <Text fontSize={['xs', 'xs', 'sm']} color={useColorModeValue('teal.800', 'teal.200')}>
                    I do not always understand the textbook
                </Text>
                </Box>
                </Link>
                <Link to="/studywithme">
                <Box as='button' bg={useColorModeValue('teal.100', 'gray.800')} m={1} p={4} borderRadius="md"  _hover={{ bg: useColorModeValue('teal.100', 'gray.700') }} _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                <Text fontSize={['xs', 'xs', 'sm']} color={useColorModeValue('teal.800', 'teal.200')}>
                    Can't tell apart the important information when studying
                </Text>
                </Box>
                </Link>
                <Link to="/studywithme">
                <Box as='button' bg={useColorModeValue('teal.100', 'gray.800')} m={1} p={4} borderRadius="md"  _hover={{ bg: useColorModeValue('teal.100', 'gray.700') }} _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                <Text fontSize={['xs', 'xs', 'sm']} color={useColorModeValue('teal.800', 'teal.200')}>
                    Do not know the possible questions I might be asked from the things I study
                </Text>
                </Box>
                </Link>
                <Text fontSize={['sm', 'md', 'lg']} >
                    Math Problems
                </Text>
                <Link to='/askfiona'>
                <Box as='button' bg={useColorModeValue('teal.100', 'gray.800')} m={1} p={4} borderRadius="md"  _hover={{ bg: useColorModeValue('teal.100', 'gray.700') }} _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                    <Text fontSize={['xs', 'xs', 'sm']} color={useColorModeValue('teal.800', 'teal.200')}>
                       I struggle to solve math related problems
                    </Text>
                </Box>
                </Link>
                <Link to='/askfiona'>
                <Box as='button' bg={useColorModeValue('teal.100', 'gray.800')} m={1} p={4} borderRadius="md"  _hover={{ bg: useColorModeValue('teal.100', 'gray.700') }} _focus={{
                boxShadow:
                    '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                }}>
                    <Text fontSize={['xs', 'xs','sm']} color={useColorModeValue('teal.800', 'teal.200')}>
                      I do not always understand math solutions
                    </Text>
                </Box>
                </Link>
            </Box>
    
        </VStack>


        <Box bg="teal.100" p={4} borderRadius="md">
          <Text fontSize={['md', 'lg', 'xl']} color="teal.800">
            We're not just a chatbot; we're a solution tailored to your learning needs.
          </Text>
        </Box>
        <Flex p={1}>
            <Link href="https://wa.me/27681508705?text=hi" passHref>
              <Button width="300px" colorScheme="blue">
                Sign Up
              </Button>
            </Link>
          </Flex>
          <Flex p={2}>
            <Heading as="h1" size="lg" fontFamily="logo" textAlign="center">
              For stress-free and enjoyable study sessions
            </Heading>
          </Flex>
      </VStack>
    );
  }

  export default OurTools
