import React from 'react'
import { Flex, Heading, Text, VStack,Button,Link,Box, useColorModeValue } from '@chakra-ui/react'

// function SmartRevision() {
//   return (

//     <VStack m={2}>
//        <Flex p={2} direction='column' textAlign='center'>
//            <Heading size='lg' fontFamily='logo' textAlign='center'>
//               Revision Made Easy in 3 Steps
//            </Heading>
//            <Text as='em'mt={1}>
//            We have made revision easy and effective with our interactive AI study tools
//            </Text>
//        </Flex>
     
//        <Flex direction='column' textAlign='start' >
  
//            <Flex direction='column' textAlign='start' p={2}>
//                 <Flex  shadow={useColorModeValue('md','none')}marginY={2} bg={useColorModeValue('blackAlpha.50','blue.800')} borderRadius='md'>
//                   <Box p={2}>
//                     <Heading size='4xl' color={useColorModeValue('blue.100','green.100')} fontFamily='logo'>1</Heading>
//                   </Box>
//                   <Box p={2}>
//                     <Heading size='md'>
//                     Choose a subject topic to revise.
//                     </Heading>
//                     <Text fontSize={['md', 'lg', 'xl']}>
//                       We will generate a short 15 minutes test for you.
//                     </Text>
//                   </Box>
//                 </Flex>
//                 <Flex  shadow={useColorModeValue('md','none')} marginY={2} bg={useColorModeValue('blackAlpha.50','blue.800')} borderRadius='md'>
//                   <Box p={2}>
//                     <Heading size='4xl' color={useColorModeValue('blue.100','green.100')} fontFamily='logo'>2</Heading>
//                   </Box>
//                   <Box p={2}>
//                     <Heading size='md'>
//                     Take a short test on the topic.
//                     </Heading>
//                     <Text fontSize={['sm', 'lg', 'xl']}>
//                     You will get immediate feedback on how well you know the topic.
//                     </Text>
//                   </Box>
//                 </Flex>
//                 <Flex   shadow={useColorModeValue('md','none')} marginY={2} bg={useColorModeValue('blackAlpha.50','blue.800')} borderRadius='md'>
//                   <Box p={2}>
//                     <Heading size='4xl' color={useColorModeValue('blue.100','green.100')} fontFamily='logo'>3</Heading>
//                   </Box>
//                   <Box p={2}>
//                     <Heading size='md'>
//                     Use the test feedback to address your weak points.
//                     </Heading>
//                     <Text fontSize={['sm', 'lg', 'xl']}>
//                     You can ask our AI tutor follow up questions on the things you get incorrect
//                     to better your understanding.
//                     </Text>
//                   </Box>
//                 </Flex>
//             </Flex>
//        </Flex>
     
//        <Flex p={1}>
//            <Link href='/signup' passHref>
//                <Button width='300px' colorScheme='blue'>Sign Up</Button>
//            </Link>
//        </Flex>
     
//        <Flex p={2}>
//            <Heading as='h1'size='lg' textAlign='center'color='teal.100'>
//                For stress-free and enjoyable study sessions
//            </Heading>
//        </Flex>
//    </VStack>
  
//  )
// }

// ... (your existing code)

function SmartRevision() {
  return (
    
    <VStack m={2} spacing={['1','4']}  borderBottomWidth={2}  pb={4}  minH={['auto','100vh']}>
          
      <Flex p={2} direction='column' textAlign='center'>
        <Heading size='lg' fontFamily='logo' textAlign='center'>
          Revision Made Easy in 3 Steps
        </Heading>
        <Text as='em' mt={1}>
          We have made revision easy and effective with our interactive AI study tools
        </Text>
      </Flex>

      <Flex direction='column' textAlign='start'>

        <Flex direction='column' textAlign='start' p={2} >
        <VStack spacing={['1','6']} alignItems='stretch'>
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('blackAlpha.100', 'black')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.300', 'green.100')} fontFamily='logo'>1</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                Choose a subject topic to revise.
              </Heading>
              <Text fontSize={['md', 'lg', 'xl']}>
                📚 We will generate a short 15 minutes test for you.
              </Text>
            </Box>
          </Flex>
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('blackAlpha.100', 'black')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.300', 'green.100')} fontFamily='logo'>2</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                Take a short test on the topic.
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                📝 You will get immediate feedback on how well you know the topic.
              </Text>
            </Box>
          </Flex>
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('blackAlpha.100', 'black')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.300', 'green.100')} fontFamily='logo'>3</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                Use the test feedback to address your weak points.
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                🎯 You can ask our AI tutor follow-up questions on the things you get incorrect
                to better your understanding.
              </Text>
            </Box>
          </Flex>
          </VStack>
        </Flex>
      </Flex>

      <Flex p={1}>
        <Link href='/signup' passHref>
          <Button width='300px' colorScheme='blue'>Sign Up</Button>
        </Link>
      </Flex>

      <Flex p={2}>
        <Heading as='h1' size='lg' fontFamily='logo' textAlign='center' >
          For stress-free and enjoyable study sessions
        </Heading>
      </Flex>
    </VStack>
  )
}

export default SmartRevision
