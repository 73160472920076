import React from 'react';
import {
  ChakraProvider
 
} from '@chakra-ui/react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import StudyWithMe from './pages/tools/StudyWithMe';
import AskATutor from './pages/tools/AskATutor';
import MathAssist from './pages/tools/MathAssist';
import SmartRevision from './pages/tools/SmartRevision';
import AskFiona from './pages/tools/AskFiona';
import theme from './theme';
import Layout from './components/layout/Layout';
import OurTools from './pages/tools';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Router>
          <Routes>
            <Route path="/" exact element={<HomePage/>} />
            <Route path="/home" exact element={<HomePage/>} />
            <Route path="/ourtools" exact element={<OurTools/>} />
            <Route path="/studywithme" element={<StudyWithMe/>} />
            <Route path="/askatutor" exact element={<AskATutor/>} />
            <Route path="/smartrevision" exact element={<SmartRevision/>} />
            <Route path="/mathassist" exact element={<MathAssist/>}/>
            <Route path="/askfiona" exact element={<AskFiona/>} />
          </Routes>
        </Router>
      </Layout>
    </ChakraProvider>
  );
}

export default App;
