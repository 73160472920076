import React from 'react';
import { VStack, Heading, Text, Flex, Image, Button, useColorModeValue } from '@chakra-ui/react';
import nerdhiveLogo from '../../assets/images/nerdhive-logo.png';

const MathAssist = () => {
  return (
    <VStack align='start' spacing={8} p={8} bg={useColorModeValue('gray.100', 'gray.800')}>
      {/* Section Heading */}
      <Heading as='h2' size='xl' mb={6} textAlign='center'>
        What Sets Nerdhive Apart
      </Heading>

      {/* Highlight 1: Personalized AI Tutor */}
      <Flex align='start' justify='space-between'>
        <VStack align='start' spacing={2}>
          <Heading as='h3' size='md'>
            Personalized AI Tutor
          </Heading>
          <Text>
            Nerdhive introduces Fiona, your AI study companion. Unlike traditional tutoring platforms, Fiona is an intelligent AI tutor available 24/7, ready to assist with any academic challenge.
          </Text>
        </VStack>
        <Image src={nerdhiveLogo} alt='Nerdhive Logo' boxSize='100px' />
      </Flex>

      {/* Highlight 2: 24/7 Academic Help */}
      <Flex align='start' justify='space-between'>
        {/* Add image or icon for 24/7 Academic Help */}
        <VStack align='start' spacing={2}>
          <Heading as='h3' size='md'>
            24/7 Academic Help
          </Heading>
          <Text>
            Access academic assistance directly from your phone whenever you need it. Never worry about getting stuck during study sessions again because help is always available.
          </Text>
        </VStack>
      </Flex>

      {/* Highlight 3: Uber for Academic Help */}
      <Flex align='start' justify='space-between'>
        <VStack align='start' spacing={2}>
          <Heading as='h3' size='md'>
            Uber for Academic Help
          </Heading>
          <Text>
            Think of Nerdhive as the Uber for academic help. We connect you with subject experts who are always ready to assist you with any problem you might have, anytime, anywhere.
          </Text>
        </VStack>
        {/* Add image or icon for Uber for Academic Help */}
      </Flex>

      {/* Highlight 4: AI-Powered Tutor Fiona */}
      <Flex align='start' justify='space-between'>
        {/* Add image or icon for AI-Powered Tutor Fiona */}
        <VStack align='start' spacing={2}>
          <Heading as='h3' size='md'>
            AI-Powered Tutor Fiona
          </Heading>
          <Text>
            Meet Fiona, our intelligent AI tutor. Fiona can help you with a variety of subjects, including math, science, and languages. She's like having a personal tutor in your pocket.
          </Text>
        </VStack>
      </Flex>

      {/* Add more highlights as needed */}

      {/* CTA Button for Sign Up */}
      <Button colorScheme='blue' size='lg' width='300px'>
        Sign Up for Nerdhive
      </Button>
    </VStack>
  );
};

export default MathAssist;
