import {Flex, useColorModeValue,Box,Divider,Heading, Text, VStack,Button,Image, Icon } from '@chakra-ui/react'
import React from 'react'
import nerdhiveLogo from '.././assets/images/nerdhive-logo.png';
import { Link } from 'react-router-dom';
import {FaArrowDown } from 'react-icons/fa';


function HomePage() {
    return (
      <>
    
        <VStack spacing={8} borderBottomWidth={2} pb={4} align="center" >
          <HeroSection/>
          <Divider my={4} borderColor='black' />
          <HowWeDoItSection/>
          <Divider my={4} borderColor='black' />
          <WhatWeBelieveSection/>
          <Divider my={4} borderColor='black' />
          <AboutUsSection/>
          
              
         
         
        </VStack>
      </>
    );
  }


  
function HeroSection() {
    return (
      <VStack bgGradient='linear(to-tr, teal.300, yellow.400)' py={4}>
        <Flex p={4} >
            <Image src={nerdhiveLogo} alt="Nerdhive Logo" boxSize="200px" />
        </Flex>
        <Box
        bg="whiteAlpha.700"
        color="black"
        textAlign="center"
        py={[8, 12, 16]}
        px={[4, 6, 8]}
        
      >
        
            <Heading as="h1" bgGradient='linear(to-l, #7928CA, #FF0080)'
            bgClip='text' size="2xl" fontWeight='extrabold' mb={4}>
            Built to Improve the Learning Experience.
            </Heading>
            <Text fontSize={['lg', 'xl', '2xl']} mb={6}>
            {/* Our platform and tools are designed to address common challenges faced by learners. Explore how Nerdhive makes learning easier. */}
            Our platform combines advanced technology with a human touch to offer a unique and effective way for students to get academic support.
            </Text>
            <Button as={Link} to='/ourtools' bgGradient='linear(to-r, teal.500, green.500)' size="lg">
            Discover Our Tools
            </Button>
        </Box>
        <VStack m={2} p={2} bg={useColorModeValue('whiteAlpha.400', 'blackAlpha.600')}>
            <Text textAlign='center' fontSize={['md', 'lg', 'xl']}>
            Nerdhive is a platform that empowers learners from <span style={{ fontWeight: 'bold', color: '#FF4433' }}>grade 7 to 12</span> to excel in their academic journey.
            </Text>  
        </VStack>
        <Flex p={1}>
            <Link href="https://wa.me/27681508705?text=hi" passHref>
            <Button width='300px' height='60px'  colorScheme='blue'>Sign Up Now</Button>
            </Link>
        </Flex>

        <Flex p={2}>
            <Heading as='h1' size='lg' fontFamily='logo' textAlign='center' >
            For stress-free and enjoyable study sessions
            </Heading>  
        </Flex>
         
      </VStack>
      
    );
  }

  function HowWeDoItSection() {
    return (
      <VStack p={8} bgColor={useColorModeValue('green.200','teal.400')}>
        <Heading as="h2" size="xl">
          Three Ways We Improve the Learning Experience 🚀
        </Heading>
        <VStack spacing={['1','6']} alignItems='stretch'>
          <Flex shadow={useColorModeValue('md', 'md')} marginY={2} bg={useColorModeValue('green.300', 'teal.800')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.600', 'green.100')} fontFamily='logo'>1</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                By making studying stress-free, easy, and enjoyable 📚😊
              </Heading>
              <Text fontSize={['md', 'lg', 'xl']}>
                Our tools address specific challenges faced by learners
              </Text>
            </Box>
          </Flex>
          <Flex shadow={useColorModeValue('md', 'md')} marginY={2} bg={useColorModeValue('green.300','teal.800')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.600', 'green.100')} fontFamily='logo'>2</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                By making access to academic support frictionless 🤝
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                We have subject experts who are always ready to assist you with any problem you might have, anytime, anywhere.
              </Text>
            </Box>
          </Flex>
          <Flex shadow={useColorModeValue('md', 'md')} marginY={2} bg={useColorModeValue('green.300', 'teal.800')} borderRadius='md'>
            <Box p={2}>
              <Heading size='4xl' color={useColorModeValue('blue.600', 'green.100')} fontFamily='logo'>3</Heading>
            </Box>
            <Box p={2}>
              <Heading size='md'>
                By providing access to quality study materials 📖📝
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                We have notes, worksheets, summaries, and past papers easily available on demand.
              </Text>
            </Box>
          </Flex>
        </VStack>
        <Flex p={1}>
        <Link href="https://wa.me/27681508705?text=hi" passHref>
          <Button colorScheme="blue" size="lg">
            Sign Up Now
          </Button>
        </Link>
       </Flex>
      </VStack>
    );
  }

  function WhatWeBelieveSection() {
    return (
      <VStack p={8} bgColor={useColorModeValue('green.100', 'teal.600')} w='full'>
        <Heading as="h2" size="xl">
         We Believe
        </Heading>
        <VStack spacing={['1','6']} alignItems='stretch' >
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('green.300', 'teal.900')} borderRadius='md'>
           
            <Box p={2}>
              <Heading size='md'>
                If we make studying easy and enjoyable 📚😊
              </Heading>
              <Text fontSize={['md', 'lg', 'xl']}>
                By addressing challenges learners face when studying
              </Text>
            </Box>
          </Flex>
          <Icon as={FaArrowDown} alignSelf='center' boxSizing={6}/>
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('green.300', 'teal.900')} borderRadius='md'>
            
            <Box p={2}>
              <Heading size='md'>
                Learners will study more 💡
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                Because it'll be a stress-free and enjoyable experience
              </Text>
            </Box>
          </Flex>
          <Icon as={FaArrowDown} alignSelf='center' boxSizing={6}/>
          <Flex shadow={useColorModeValue('md', 'none')} marginY={2} bg={useColorModeValue('green.300', 'teal.900')} borderRadius='md'>
            
            <Box p={2}>
              <Heading size='md'>
                Better academic results 🏆
              </Heading>
              <Text fontSize={['sm', 'lg', 'xl']}>
                Learners' marks will improve because they will be studying more
              </Text>
            </Box>
          </Flex>
        </VStack>
      </VStack>
    );
  }
  function AboutUsSection() {
    return (
      <VStack align="start" spacing={6} p={8} bgColor={useColorModeValue('green.200','teal.800')}>
        <Heading as="h2" size="xl">
        About Us
        </Heading>
        <Text fontSize={['md', 'lg', 'xl']}>
          Welcome to Nerdhive, where we're redefining the learning experience. Our mission is to provide a platform that empowers learners to excel in their academic journey.
        </Text>
        <Text fontSize={['md', 'lg', 'xl']}>
          At Nerdhive, we believe in the power of personalized learning. Our platform combines advanced technology with a human touch to offer a unique and effective way for students to get academic support.
        </Text>
        <Text fontSize={['md', 'lg', 'xl']}>
          Whether you're struggling with a specific topic, need help with homework, or just want to enhance your understanding, Nerdhive is here for you. Our team of dedicated educators and AI-powered tools work together to make learning accessible, engaging, and enjoyable.
        </Text>
        <Flex p={1}>
        <Link href="https://wa.me/27681508705?text=hi" passHref>
          <Button colorScheme="blue" size="lg">
            Join Nerdhive
          </Button>
        </Link>
       </Flex>
        {/* <Image src={aboutUsImage} alt="About Us" boxSize="100%" rounded="md" /> */}
      </VStack>
    );
  }
    
  
  export default HomePage;

  //   function FeaturesSection() {
//     return (
//       <VStack align="center" spacing={6} p={8}>
//         <Heading as="h2" size="xl">
//         Do any of these sound familiar?
//         </Heading>
//         <List styleType="disc" fontSize={['md', 'lg', 'xl']} pl={6}>
//           <ListItem>
//             Feeling lost in the textbook? Our Concept Clarifier tool breaks down complex concepts.
//           </ListItem>
//           <ListItem>
//             Struggling with math problem solutions? Our Step-by-Step Solver provides clear explanations.
//           </ListItem>
//           <ListItem>
//             Stuck on a particular problem? Our Guided Solution tool walks you through the solution.
//           </ListItem>
//           <ListItem>
//             Confused about a specific topic? Our Topic Mastery tool ensures thorough understanding.
//           </ListItem>
//           <ListItem>
//             Need practice materials like past papers and worksheets? Our Practice Hub tool has you covered.
//           </ListItem>
//         </List>
//         <Box bg="teal.100" p={4} borderRadius="md">
//           <Text fontSize={['md', 'lg', 'xl']} color="teal.800">
//             We're not just a chatbot; we're a solution tailored to your learning needs.
//           </Text>
//         </Box>
//         <Flex p={1}>
//             <Link href="https://wa.me/27681508705?text=hi" passHref>
//               <Button width="300px" colorScheme="blue">
//                 Sign Up
//               </Button>
//             </Link>
//           </Flex>
//           <Flex p={2}>
//             <Heading as="h1" size="lg" fontFamily="logo" textAlign="center">
//               For stress-free and enjoyable study sessions
//             </Heading>
//           </Flex>
//       </VStack>
//     );
//   }