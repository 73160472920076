import React from 'react';
import { VStack, Heading, Text, Box,Flex, Image, useColorModeValue } from '@chakra-ui/react';
import fionaImage from '../../assets/images/nerdhive-icon.png';

function AskFiona() {
  return (
   <VStack  bgGradient='linear(to-tr, teal.300, yellow.400)'>
   
          <Box>
            <VStack p={2} >
            <Heading as="h2" size="xl" textAlign="center">
             Meet Fiona
            </Heading>
            <Flex pt={3} pd={1}>
                  <Image src={fionaImage} alt="Image of Fiona" boxSize="200px" />
           </Flex >
           <Text fontSize={['md', 'lg', 'xl']} fontWeight="bold">
                  Our AI-powered assistant
           </Text>
           </VStack>
           <VStack  p={3} borderRadius='md'>
           <Flex direction='column' shadow={useColorModeValue('md', 'md')} p={2} marginY={2}bg={useColorModeValue('whiteAlpha.300', 'blackAlpha.600')} borderRadius='md'>
              <Text fontSize={['md', 'lg', 'xl']} fontFamily='heading' mt={2} ml={3}  >
                  Fiona's training is aligned with the National Curriculum and Assessment Policy (CAPS) and is integrated into all our study tools.
              </Text>
           </Flex>
           <Flex  direction='column' shadow={useColorModeValue('md', 'md')} p={2} marginY={2}bg={useColorModeValue('whiteAlpha.400', 'blackAlpha.600')} borderRadius='md'>
              <Text fontSize={['md', 'lg', 'xl']} fontFamily='heading' mt={2} ml={3}  >
                  She is always available to :
              </Text>
              <VStack align='start' spacing={['2','5']}  p={4}>
              
                  <Text>
                  📚 Teach you about any topic and answer any questions you might have.
                  </Text>
                  <Text>
                  📷 Simplify the information in your textbook. Just snap and send.
                  </Text>
                  <Text>
                  🧮 Help you understand math questions and solutions to math questions
                  </Text>
                  <Text>
                  🎓 Guide you on how to approach any topic to master it.
                  </Text>
                  <Text>
                  🔄 Help you revise
                  </Text>
                  <Text>
                  📊 Track your progress.
                  </Text>
                  <Text>
                  and more...
                  </Text>
              </VStack>
              </Flex>
           </VStack>
           
          </Box>
   </VStack>
  )
}

export default AskFiona;